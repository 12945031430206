import { FC, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { CaretLeft, ArrowRight, PaperPlaneRight, X, FastForward } from 'phosphor-react';
import { errorStates, landingStates, overviewStates, StudyStateString } from 'logic/useStudy';
import { Button } from 'components/Button/Button';
import css from './Navigation.module.scss';

const questionDefaultNextIcon = <ArrowRight />;
const questionSkipIcon = <FastForward />;
const overviewNextIcon = <PaperPlaneRight />;
const errorNextIcon = <X />;

const questionPreviousIcon = <CaretLeft />;

export interface NavigationProps {
  state: StudyStateString | 'intro' | 'instruction';
  submitting?: boolean;
  disableNext?: boolean;
  disablePrevious?: boolean;
  showSkip?: boolean;
  hidePrevious?: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

export const Navigation: FC<NavigationProps> = ({
  state,
  submitting = false,
  disableNext = false,
  disablePrevious = false,
  showSkip = false,
  hidePrevious = false,
  onPrevious,
  onNext,
}) => {
  const introNextTitle = <FormattedMessage id="navigation.introStartButton" defaultMessage="Start" />;
  const questionSkipTitle = <FormattedMessage id="navigation.questionSkipButton" defaultMessage="Skip" />;
  const questionNextTitle = showSkip ? (
    questionSkipTitle
  ) : (
    <FormattedMessage id="navigation.questionNextButton" defaultMessage="Continue" />
  );
  const overviewNextTitle = <FormattedMessage id="navigation.overviewSubmitButton" defaultMessage="Submit" />;
  const errorNextTitle = <FormattedMessage id="navigation.errorCloseButton" defaultMessage="Close" />;

  const questionPreviousTitle = {
    tablet: <FormattedMessage id="navigation.questionBackButton" defaultMessage="Previous" />,
    mobile: (
      <FormattedMessage
        id="navigation.questionBackMobileButton"
        defaultMessage="Back to previous"
        description="Longer text on mobile to make context clearer"
      />
    ),
  };
  const introPreviousTitle = {
    tablet: <FormattedMessage id="navigation.introBackButton" defaultMessage="Cancel" />,
    mobile: (
      <FormattedMessage
        id="navigation.introBackMobileButton"
        defaultMessage="Cancel & quit"
        description="Longer text on mobile to make context clearer"
      />
    ),
  };

  // We want to show "Back" on mobile & "Quit" on desktop
  const instructionPreviousTitle = {
    tablet: introPreviousTitle.tablet,
    mobile: (
      <FormattedMessage
        id="navigation.instructionBackMobileButton"
        defaultMessage="Back to previous"
        description="Only shown on mobile when navigating between intro & instruction views"
      />
    ),
  };

  const questionNextIcon = showSkip ? questionSkipIcon : questionDefaultNextIcon;

  const next = useMemo(() => {
    if (state === 'intro') return { title: questionNextTitle, icon: questionNextIcon };
    if (state === 'instruction') return { title: introNextTitle, icon: questionNextIcon };
    if (errorStates.includes(state)) return { title: errorNextTitle, icon: errorNextIcon };

    if (landingStates.includes(state)) return { title: introNextTitle, icon: questionNextIcon };
    if (overviewStates.includes(state)) return { title: overviewNextTitle, icon: overviewNextIcon };
    return { title: questionNextTitle, icon: questionNextIcon };
  }, [state, questionNextTitle, overviewNextTitle]);

  const previous = useMemo(() => {
    if (hidePrevious) return null;
    if (state === 'done') return null;
    if (state === 'intro') return { title: introPreviousTitle, icon: null };
    if (state === 'instruction') return { title: instructionPreviousTitle, icon: null };
    if (errorStates.includes(state)) return null;

    if (landingStates.includes(state)) return { title: introPreviousTitle, icon: null };
    return { title: questionPreviousTitle, icon: questionPreviousIcon };
  }, [state, questionPreviousTitle, introPreviousTitle, hidePrevious]);

  return (
    <nav className={cn(css.navigation, !previous && css.noPrevious)}>
      <Button variant={showSkip ? 'filled' : 'primary'} onClick={onNext} loading={submitting} disabled={disableNext}>
        {next.title} <next.icon.type size={28} {...next.icon.props} />
      </Button>
      {previous && (
        <Button variant="secondary" className={css.previous} onClick={onPrevious} disabled={disablePrevious || submitting}>
          {previous.icon && <previous.icon.type size={18} weight="fill" {...previous.icon.props} />}
          <span className="tablet">{previous.title.tablet}</span>
          <span className="mobile">{previous.title.mobile}</span>
        </Button>
      )}
    </nav>
  );
};
