import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { createFocusTrap } from 'focus-trap';
import { MediaDocument } from 'common/StudyDocument';
import { Swiper } from './Swiper';
import css from './Media.module.scss';
const fullscreenUrl = (link: string, image: string) => `/api/image?lnk=${link}&img=${image}`;
const thumbnailUrl = (link: string, image: string) => fullscreenUrl(link, `800x600M-${image}`);

export const ImageSlider: FC<{ images: MediaDocument[] }> = ({ images: mediaImages }) => {
  const [isModal, setIsModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const portalMount = useMemo(() => document.createElement('div'), []);
  const portalFocusTrap = useMemo(() => createFocusTrap(portalMount), [portalMount]);

  const link = typeof window !== 'undefined' ? window.location.pathname.replace('/', '') : '';

  const images = useMemo(
    () =>
      mediaImages.map(({ target, description }) => ({
        url: isModal ? fullscreenUrl(link, target) : thumbnailUrl(link, target),
        caption: description || '',
      })),
    [mediaImages, link, isModal]
  );

  useEffect(() => {
    portalFocusTrap.deactivate();
  }, [portalFocusTrap]);

  useEffect(() => {
    if (isModal && portalMount) {
      portalMount.className = css.imageModal;
      portalMount.setAttribute('aria-modal', 'true');
      document.body.appendChild(portalMount);

      portalFocusTrap.activate();

      return () => {
        portalFocusTrap.deactivate();
        document.body.removeChild(portalMount);
      };
    }
  }, [isModal, portalMount, portalFocusTrap]);

  const handleModalOpen = useCallback(() => {
    setIsModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModal(false);
  }, []);

  const content = (
    <Swiper
      images={images}
      modal={isModal}
      initialSlide={initialSlide}
      onSlide={slide => setInitialSlide(slide)}
      onOpen={handleModalOpen}
      onClose={handleModalClose}
    />
  );

  if (isModal) {
    return (
      <div className={css.media}>
        <div className={css.content}>{createPortal(content, portalMount)}</div>
      </div>
    );
  }

  return content;
};
