import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { getDecimals } from 'common/answerLogic';
import { Input } from 'components/Input/Input';
import { Slider } from 'components/Slider/Slider';
import { Text } from 'components/Text/Text';
import css from './Count.module.scss';

export interface CountProps {
  id: string;
  min: number;
  max: number;
  decimals?: number;
  unit?: string;
  value: number | null;
  disabled?: boolean;
  onChange: (optionID: number) => void;
}

export const Count: FC<CountProps> = ({ id, min, max, decimals = 0, unit = 'pcs.', value, disabled = false, onChange }) => {
  const [textValue, setTextValue] = useState('');

  const position = value ?? min + (max - min) / 2;

  useEffect(() => {
    setTextValue((value ?? 0).toFixed(decimals));
  }, [value]);

  const handleChange = useCallback(
    (value: string | number) => {
      let number = typeof value === 'number' ? value : parseFloat(value) || 0;
      if (number < min) number = min;
      if (number > max) number = max;

      const decimalCount = getDecimals(number);
      if (decimalCount !== decimals) {
        const power = Math.pow(10, decimals);
        number = Math.round((number + Number.EPSILON) * power) / power;
      }

      onChange(number);

      return number;
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    setTextValue(text => handleChange(text.replace(/,/g, '.')).toFixed(decimals));
  }, [decimals, handleChange]);

  const handleTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  }, []);

  const handleSliderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event.target.value || 0);
    },
    [handleChange]
  );

  const handleSliderClick = useCallback(() => {
    if (value === null) return onChange(position);
  }, [value === null, position]);

  const handleNumberClick = useCallback(
    (number: number) => {
      if (disabled) return;
      onChange(number);
    },
    [disabled, onChange]
  );

  const handleMinClick = useCallback(() => handleNumberClick(min), [min, handleNumberClick]);
  const handleMaxClick = useCallback(() => handleNumberClick(max), [max, handleNumberClick]);

  return (
    <div className={cn(css.count, disabled && css.disabled)}>
      <div className={css.input}>
        <Input prefix={unit} value={textValue} fitContent disabled={disabled} onChange={handleTextChange} onBlur={handleBlur} />
      </div>

      <Slider
        id={id}
        min={min}
        max={max}
        value={position}
        step={1}
        aria-hidden
        disabled={disabled}
        onInput={handleSliderChange} // For consistent cross-browser live update functionality
        onChange={handleSliderChange} // For IE fallback
        onClick={handleSliderClick} // For desktop initial value click-in-place
        onTouchStart={handleSliderClick} // For mobile initial value click-in-place
      />

      <div className={css.range} aria-hidden>
        <Text variant="label" onClick={handleMinClick}>
          {min}
        </Text>
        <Text variant="label" onClick={handleMaxClick}>
          {max}
        </Text>
      </div>
    </div>
  );
};
