import {
  AnswerDocument,
  ChooseMultipleAnswerDocument,
  ChooseOneAnswerDocument,
  CountAnswerDocument,
  NPSAnswerDocument,
  StarRatingAnswerDocument,
} from './AnswerDocument';
import { QuestionType, QuestionDocument, LogicRuleType, LogicRuleDocument, LogicRuleTarget } from './StudyDocument';

/** Will return the question the logic rule is pointing at, or the ID of the logic rule if ends study */
const solveLogicRule = (logicRule: LogicRuleDocument | null | undefined, questions: QuestionDocument[], index: number) => {
  const defaultNextQuestion = index < questions.length - 1 ? questions[index + 1] : LogicRuleTarget.JUMP_TO_END;

  if (!logicRule) return defaultNextQuestion;
  const { nextQuestionID } = logicRule;

  if (nextQuestionID === LogicRuleTarget.JUMP_TO_NEXT) return defaultNextQuestion;
  if (nextQuestionID === LogicRuleTarget.JUMP_TO_END) return LogicRuleTarget.JUMP_TO_END;
  if (nextQuestionID === LogicRuleTarget.REJECT_ANSWER) return LogicRuleTarget.REJECT_ANSWER;

  const nextQuestion = questions.find(({ questionID }) => questionID === nextQuestionID);
  if (!nextQuestion) return defaultNextQuestion;

  return nextQuestion;
};

/**
 * Take into account the current question, answer & logic rules, and return the next question
 * @returns Question object or logic rule ID to indicate study end
 */
export const calculateNextQuestion = (questions: QuestionDocument[], currentQuestionID: number, currentAnswer: AnswerDocument | null) => {
  const index = questions.findIndex(({ questionID }) => questionID === currentQuestionID);
  const question = questions[index];
  if (!question) return LogicRuleTarget.JUMP_TO_END;

  const answer = currentAnswer;

  const defaultNextQuestion = index < questions.length - 1 ? questions[index + 1] : LogicRuleTarget.JUMP_TO_END;

  const { questionTypeID, logicRules } = question;
  if (!logicRules || !logicRules.length) return defaultNextQuestion;

  // Try to find a logic rule that matches the current answer
  if (answer) {
    switch (questionTypeID) {
      case QuestionType.CHOOSE_ONE:
      case QuestionType.CHOOSE_MULTIPLE:
        const { questionOptionIDs } = answer as ChooseOneAnswerDocument | ChooseMultipleAnswerDocument;

        // If question is skipped, this might be undefined
        if (!questionOptionIDs) break;

        // Select the first matching logic rule in the order the user selected the options
        for (const optionID of questionOptionIDs) {
          const logicRule = logicRules.find(({ ruleTypeID, questionOptionID }) => {
            if (ruleTypeID !== LogicRuleType.OPTION) return false;
            if (questionOptionID !== optionID) return false;
            return true;
          });

          if (!logicRule) continue;

          return solveLogicRule(logicRule, questions, index);
        }

        break;

      case QuestionType.COUNT:
      case QuestionType.STAR_RATING:
      case QuestionType.NPS:
        const { number } = answer as CountAnswerDocument | StarRatingAnswerDocument | NPSAnswerDocument;

        const logicRule = logicRules.find(({ ruleTypeID, minValue, maxValue }) => {
          if (ruleTypeID !== LogicRuleType.OPTION) return false;
          if (minValue !== null && number < minValue) return false;
          if (maxValue !== null && number > maxValue) return false;
          return true;
        });

        if (!logicRule) break;

        return solveLogicRule(logicRule, questions, index);
    }
  }

  // No logic rule found, try to find a custom default next question
  const defaultNextQuestionLogicRule = logicRules.find(({ ruleTypeID }) => ruleTypeID === LogicRuleType.DEFAULT);
  if (!defaultNextQuestionLogicRule) return defaultNextQuestion;

  return solveLogicRule(defaultNextQuestionLogicRule, questions, index);
};
