import { FC, useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStudyState } from 'logic/studyStore';
import type { StudyStateString } from 'logic/useStudy';
import { Button } from 'components/Button/Button';
import { Text } from 'components/Text/Text';
import css from './Timeout.module.scss';

const SHOW_DIALOG_WHEN_MINUTES_LEFT = 5;

export interface TimeoutProps {
  state: StudyStateString;
  onTimeout: () => void;
}

export const Timeout: FC<TimeoutProps> = ({ state, onTimeout }) => {
  const responseTimeStart = useStudyState(state => state.responseTimeStart);
  const timeoutInMinutes = useStudyState(state => state.document.timeoutInMinutes);
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleTimeoutCheck = useCallback(() => {
    if (!responseTimeStart) return;
    if (state === 'error-timeout') return;
    if (state === 'submit-study') return;
    if (state === 'done') return;

    const durationInMinutes = (Date.now() - responseTimeStart.getTime()) / 1000 / 60;
    const timeLeftInMinutes = timeoutInMinutes - durationInMinutes;

    if (timeLeftInMinutes < SHOW_DIALOG_WHEN_MINUTES_LEFT && !isOpen && !isWarningShown) {
      setIsOpen(true);
    }

    if (timeLeftInMinutes <= 0) {
      onTimeout();
    }
  }, [state, isOpen, isWarningShown, timeoutInMinutes, responseTimeStart, onTimeout]);

  const handleOKClick = useCallback(() => {
    setIsWarningShown(true);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(handleTimeoutCheck, 1000);
    return () => clearInterval(interval);
  }, [handleTimeoutCheck]);

  useEffect(() => {
    setIsWarningShown(false);
  }, [responseTimeStart]);

  if (!isOpen) return null;

  return (
    <div className={css.backdrop}>
      <article className={css.dialog}>
        <Text>
          <FormattedMessage
            id="timeout.timeoutWarning"
            defaultMessage="You have {minutes} {minutes, plural, =0 {minutes} =1 {minute} other {minutes}} left to complete this study. If the time runs out, your answer will not be recorded."
            values={{ minutes: SHOW_DIALOG_WHEN_MINUTES_LEFT }}
          />
        </Text>

        <div className={css.actions}>
          <Button onClick={handleOKClick}>
            <FormattedMessage id="timeout.okButton" defaultMessage="OK" />
          </Button>
        </div>
      </article>
    </div>
  );
};
