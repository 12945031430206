import { useCallback, useMemo, useState } from 'react';
import { QuestionType, QuestionDocument } from 'common/StudyDocument';
import type { AnswerDocument, ChooseMultipleAnswerDocument } from 'common/AnswerDocument';
import { useAnswerState } from './answerStore';
import { useVideoState, VideoState } from './videoStore';

const optionsShouldBeDisabledAtMax = [QuestionType.CHOOSE_MULTIPLE, QuestionType.RANK];

// Freetext handles value change differently, this is just to make React happy
const freeTextValueChange = () => {};

export const useQuestionLogic = (question: QuestionDocument) => {
  const { answers, setAnswer, setComment } = useAnswerState();

  const video = useVideoState(
    useCallback(
      (state: VideoState) => (question?.questionID ? state.videoMap.get(question.questionID) : null) || null,
      [question?.questionID]
    )
  );

  const isVideoWatched = video ? video.isFinished : true;

  // Rank uses this key to detect when to animate list positions
  const [rankAnimationKey, setRankAnimationKey] = useState(Date.now());

  const { questionID, questionTypeID } = question;
  const value: AnswerDocument | null = answers[questionID] ?? null;

  const isOptionDisabled = useMemo(() => {
    if (!optionsShouldBeDisabledAtMax.includes(questionTypeID)) return false;
    if (!question.maxValue || !value) return false;
    if ((value as ChooseMultipleAnswerDocument).questionOptionIDs?.length >= question.maxValue) return true;
    return false;
  }, [questionTypeID, question.maxValue, value]);

  const handleSingleOptionChange = useCallback(
    (optionID: number) => {
      setAnswer<QuestionType.CHOOSE_ONE>(question as any, {
        questionOptionIDs: [optionID],
      });
    },
    [question]
  );

  const handleMultipleOptionsChange = useCallback(
    (optionID: number) => {
      const state = useAnswerState.getState().answers;

      const { questionOptionIDs = [] } = (state[question.questionID] as ChooseMultipleAnswerDocument) || {};
      const isSelected = questionOptionIDs.includes(optionID);

      setRankAnimationKey(Date.now());

      setAnswer<QuestionType.CHOOSE_MULTIPLE | QuestionType.RANK>(question as any, {
        questionOptionIDs: isSelected ? questionOptionIDs.filter(id => id !== optionID) : [...questionOptionIDs, optionID],
      });
    },
    [question]
  );

  const handleNumberChange = useCallback(
    (number: number) => {
      setAnswer<QuestionType.COUNT | QuestionType.NPS>(question as any, {
        number,
      });
    },
    [question]
  );

  const handleCommentChange = useCallback(
    (comment: string) => {
      setComment(question, comment);
    },
    [question]
  );

  const handleRankSwap = useCallback((optionID: number, index: number) => {
    const state = useAnswerState.getState().answers;

    const { questionOptionIDs = [] } = (state[question.questionID] as ChooseMultipleAnswerDocument) || {};

    const ids = [...questionOptionIDs].filter(id => id !== optionID);
    ids.splice(index, 0, optionID);

    setAnswer<QuestionType.RANK>(question as any, {
      questionOptionIDs: ids,
    });
  }, []);

  const typeToChange: Record<QuestionType, (optionID: number) => void> = {
    [QuestionType.CHOOSE_ONE]: handleSingleOptionChange,
    [QuestionType.CHOOSE_MULTIPLE]: handleMultipleOptionsChange,
    [QuestionType.FREE_TEXT]: freeTextValueChange,
    [QuestionType.COUNT]: handleNumberChange,
    [QuestionType.STAR_RATING]: handleNumberChange,
    [QuestionType.PHOTO]: handleMultipleOptionsChange,
    [QuestionType.RANK]: handleMultipleOptionsChange,
    [QuestionType.NPS]: handleNumberChange,
  };

  return {
    value: value as AnswerDocument | null,
    handleValueChange: typeToChange[questionTypeID] as (optionID: number) => void,
    handleCommentChange,
    handleRankSwap,
    rankAnimationKey,
    isVideoWatched,
    isOptionDisabled: isOptionDisabled || !isVideoWatched,
  };
};
