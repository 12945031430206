import { FC, useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'phosphor-react';
import { Feature, Features } from 'components/Features/Features';
import { Text } from 'components/Text/Text';
import { Flip } from 'components/Text/Flip';
import css from './Overview.module.scss';
import { Banner } from 'components/Banner/Banner';

export interface OverviewProps {
  title: string | JSX.Element;
  subtitle: (string | JSX.Element)[];
  error?: string | null;
  errorDetails?: string | null;
  showCrowstWebsiteLink?: boolean;
  showCrowstAppPromo?: boolean;
}

export const Overview: FC<OverviewProps> = ({
  title,
  subtitle,
  error = null,
  errorDetails = null,
  showCrowstWebsiteLink = false,
  showCrowstAppPromo = false,
}) => {
  const isRejectReasonShown = process.env.NEXT_PUBLIC_SHOW_REJECT_REASON !== 'false';

  const featureItems = useMemo<Feature[]>(
    () => [
      {
        id: 'link',
        title: <a href="https://www.crowst.com/">www.crowst.com</a>,
        icon: <Link />,
      },
    ],
    []
  );

  return (
    <article className={css.overview}>
      <form className={css.content}>
        <Flip variant="hero">{title}</Flip>
        {subtitle.map((subtitle, i) => (
          <Text key={i}>{subtitle}</Text>
        ))}

        {isRejectReasonShown && <div className={css['error-details']}>{errorDetails}</div>}

        {showCrowstWebsiteLink && <Features items={featureItems} />}

        <div className={cn(css.app, showCrowstAppPromo && css.appVisible)} aria-hidden={!showCrowstAppPromo}>
          <div>
            <img alt="crowst app icon" src="/app_icon.png" width={28} height={28} />
            <Text variant="body">Crowst App</Text>
          </div>
          <div>
            <img alt="app store badge" src="/app_store_badge.svg" height={40} />
            <img alt="google play badge" src="/play_store_badge.png" height={58} />
          </div>

          <Text variant="fineprint">
            Google Play and the Google Play logo are trademarks of Google LLC.
            <br />
            App Store® and Apple logo® are trademarks of Apple Inc.
          </Text>
        </div>
        {error && <Banner type="error">{error}</Banner>}
      </form>
    </article>
  );
};
