import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Info } from 'phosphor-react';
import { Text } from '../../Text/Text';
import css from './Header.module.scss';

export interface HeaderProps {
  percentage: number;
  answered: number;
  hidden?: boolean;
}

export const Header: FC<HeaderProps> = ({ percentage, answered, hidden = false }) => (
  <header className={css.container} tabIndex={hidden ? -1 : undefined} aria-hidden={hidden} style={hidden ? { height: 0 } : {}}>
    <div className={cn(css.header, hidden && css.hidden)}>
      <div className={css.logo}>
        <img src="/crowst_logo_black.svg" alt="crowst logo" />
      </div>

      <div className={css.status}>
        <div className={css.progress}>
          <div style={{ width: `${percentage}%` }} />
        </div>
        <Text variant="fineprint">
          <FormattedMessage
            id="header.completionPercentageText"
            defaultMessage="{percentage}% complete"
            values={{ percentage: Math.floor(percentage) }}
          />
          {' · '}
          <FormattedMessage
            id="header.answeredQuestionCountText"
            defaultMessage="{count, plural, =0 {# questions} =1 {# question} other {# questions}} answered"
            values={{ count: answered }}
          />
        </Text>
      </div>

      {/* <Info size={22} weight="bold" /> */}
    </div>
  </header>
);
