import { FC, HTMLProps } from 'react';
import cn from 'classnames';
import css from './Text.module.scss';

type TextVariants = 'hero' | 'header' | 'subheader' | 'body' | 'input' | 'label' | 'button' | 'fineprint';
type TextElements = keyof JSX.IntrinsicElements;

export interface TextProps extends HTMLProps<HTMLParagraphElement> {
  variant?: TextVariants;
  element?: TextElements;
}

const variantToElement: Record<TextVariants, TextElements> = {
  hero: 'h1',
  header: 'h2',
  subheader: 'h3',
  body: 'p',
  input: 'span',
  label: 'label',
  button: 'span',
  fineprint: 'p',
};

export const Text: FC<TextProps> = ({ variant = 'body', element, className = null, ...other }) => {
  const Element = (element || variantToElement[variant]) as 'p';

  return <Element className={cn('text', variant, css.base, css[variant], className)} {...other} />;
};
