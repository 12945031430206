import { FC, useCallback, useMemo } from 'react';
import { QuestionType } from 'common/StudyDocument';
import { Checkbox } from 'components/Checkbox/Checkbox';
import css from './Option.module.scss';

export interface OptionProps {
  questionID: number;
  questionTypeID: QuestionType;
  optionID: number;
  title: string;
  selected: number[];
  disabled?: boolean;
  onChange: (optionID: number) => void;
}

export const Option: FC<OptionProps> = ({ questionID, questionTypeID, optionID, title, selected, disabled = false, onChange }) => {
  const isSelected = useMemo(() => selected.includes(optionID), [optionID, selected]);
  const isDisabled = !isSelected && disabled;

  const handleChange = useCallback(() => {
    if (isDisabled) return;
    onChange(optionID);
  }, [optionID, isDisabled, onChange]);

  switch (questionTypeID) {
    case QuestionType.CHOOSE_ONE:
      return (
        <Checkbox
          radio
          name={`question-${questionID}-answer`}
          value={`option-${optionID}`}
          className={css.answer}
          checked={isSelected}
          disabled={isDisabled}
          onChange={handleChange}
        >
          {title}
        </Checkbox>
      );
    case QuestionType.CHOOSE_MULTIPLE:
      return (
        <Checkbox className={css.answer} checked={isSelected} disabled={isDisabled} onChange={handleChange}>
          {title}
        </Checkbox>
      );
    case QuestionType.RANK:
      return (
        <Checkbox className={css.answer} checked={isSelected} disabled={isDisabled} onChange={handleChange}>
          {title}
        </Checkbox>
      );
    default:
      return null;
  }
};
