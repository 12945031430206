import { FC, HTMLProps, useMemo } from 'react';
import cn from 'classnames';
import css from './Input.module.scss';

export interface InputProps extends HTMLProps<HTMLInputElement> {
  fitContent?: boolean;
}

export const Input: FC<InputProps> = ({ id, fitContent = false, value, label = null, prefix, className, ...other }) => {
  const inputStyle = useMemo(() => {
    if (!fitContent) return undefined;
    return { width: `${value}`.length + 'ch' };
  }, [value, fitContent]);

  return (
    <div className={cn(css.input, className, 'input', other.disabled && css.disabled)}>
      {label && (
        <label htmlFor={id} className={css.label}>
          {label}
        </label>
      )}
      <input id={id} value={value} prefix={prefix} style={inputStyle} {...other} />
      {prefix && <span className={css.prefix}>{prefix}</span>}
    </div>
  );
};
