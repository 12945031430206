import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import type { QuestionDocument } from 'common/StudyDocument';
import { errorStates, loadingStates, overviewStates, useStudy } from 'logic/useStudy';
import { useOverview } from 'logic/useOverview';
import { useError } from 'logic/useError';
import { Hero } from 'components/Hero/Hero';
import { HeroIllustrationBottom, HeroIllustrationTop } from 'components/Hero/HeroIllutrations';
import { Navigation } from './Navigation/Navigation';
import { Question } from './Question/Question';
import { Header } from './Header/Header';
import { Overview } from './Overview/Overview';
import { Instructions } from './Instructions/Instructions';
import { QuestionSkeleton } from './Question/QuestionSkeleton';
import { Timeout } from './Timeout/Timeout';
import css from './Study.module.scss';

const QuestionWrapper: FC<{
  id: number | string;
  height?: number;
  currentRef: RefObject<HTMLDivElement>;
  isCurrent: boolean;
  isPrevious: boolean;
}> = ({ id, height, currentRef, isCurrent, isPrevious, children }) => (
  <div
    key={id}
    className={cn(css.question, isPrevious && css.exit, id === 'instructions' && css.instruction)}
    ref={isCurrent ? currentRef : null}
    style={isPrevious ? { height } : {}}
    aria-hidden={isPrevious}
  >
    {children}
  </div>
);

export const Study: FC = () => {
  const {
    state,
    error,
    loadStudyErrorInfo,
    study,
    question,
    history,
    isQuestionAnswered,
    isVideoWatched,
    isSkipShown,
    handleNext,
    handlePrevious: _handlePrevious,
    handleTimeout,
  } = useStudy();
  const overviewContent = useOverview(state);
  const errorContent = useError(state);
  const [acknowledgement, setAcknowledgement] = useState(false);

  const [mobileIntroView, setMobileIntroView] = useState<'intro' | 'instruction'>('intro');

  const questions = study.questions;

  // Make exit animation remember the previous question height
  const currentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const isLoading = loadingStates.includes(state);
  const isSubmitting = state === 'submit-study';
  const isError = errorStates.includes(state);

  // Update previous question height when question changes
  useEffect(() => {
    if (!currentRef.current) return;

    const height = currentRef.current.clientHeight;
    setHeight(value => (value === 0 ? height : value));

    return () => {
      setHeight(height);
    };
  }, [question]);

  const handlePrevious = useCallback(() => {
    if (mobileIntroView === 'instruction') return setMobileIntroView('intro');
    return _handlePrevious();
  }, [mobileIntroView, _handlePrevious]);

  // Show rights side of intro instructions
  const renderInstruction = useCallback(() => {
    const isCurrent = isLoading || state === 'do-intro';
    const isPrevious = state === 'do-study' && !history.length;

    if (!isCurrent && !isPrevious) return null;

    return (
      <QuestionWrapper id="instructions" height={height} currentRef={currentRef} isCurrent={isCurrent} isPrevious={isPrevious}>
        {isLoading && <QuestionSkeleton />}
        {!isLoading && <Instructions study={study} onAcknowledgement={setAcknowledgement} />}
      </QuestionWrapper>
    );
  }, [state, study, history, height, currentRef, isLoading]);

  const renderQuestion = useCallback(
    (item: QuestionDocument) => {
      const isCurrent = state === 'do-study' && item.questionID === question?.questionID;
      const isPrevious = history[history.length - 1]?.questionID === item.questionID;

      if (!isCurrent && !isPrevious) return null;

      return (
        <QuestionWrapper
          key={item.questionID}
          id={item.questionID}
          height={height}
          currentRef={currentRef}
          isCurrent={isCurrent}
          isPrevious={isPrevious}
        >
          <Question question={item} />
        </QuestionWrapper>
      );
    },
    [state, question, history, height, currentRef]
  );

  const renderOverview = useCallback(() => {
    if (!overviewStates.includes(state)) return null;
    return (
      <div className={cn(css.question)}>
        <Overview
          {...overviewContent}
          error={error}
          showCrowstWebsiteLink={state === 'done'}
          showCrowstAppPromo={['submit-study', 'done'].includes(state) && study.showCrowstAppPromo}
        />
      </div>
    );
  }, [state, error, overviewContent.title, overviewContent.subtitle]);

  const renderError = useCallback(() => {
    if (!errorStates.includes(state)) return null;

    return (
      <div className={cn(css.question)}>
        <Overview {...errorContent} errorDetails={loadStudyErrorInfo} showCrowstAppPromo={study.showCrowstAppPromo} />
      </div>
    );
  }, [state, errorContent.title, errorContent.subtitle]);

  let completionPercentage = (Math.max(0, (question?.questionNumber || 0) - 1) / questions.length) * 100;
  if (overviewStates.includes(state)) {
    completionPercentage = 100;
  }

  const isIntroView = state === 'do-intro';
  const isIntroHidden = !isIntroView || mobileIntroView !== 'intro';
  const isDoneView = state === 'done';

  const isHeaderVisible = !isLoading && !isError && !isIntroView;
  const isNextDisabled = !acknowledgement || (!isError && !isIntroView && !isQuestionAnswered) || !isVideoWatched || isDoneView;

  const isPreviousDisabled = history.length === 0 && !(isIntroView && isIntroHidden);
  const isBackHidden = state === 'do-study' && study.isBackDisabled;

  return (
    <main className={cn(css.study, !isIntroHidden && css.studyIntro)}>
      <aside className={cn(css.side, isIntroHidden && css.sideHidden)} aria-hidden={!isIntroView} tabIndex={isIntroView ? 0 : -1}>
        <Hero study={study} onQuit={() => {}} onNext={() => setMobileIntroView('instruction')} />
      </aside>
      <article className={css.content}>
        <Header percentage={completionPercentage} answered={history.length} hidden={!isHeaderVisible} />

        {renderInstruction()}

        {questions.map(renderQuestion)}
        {renderOverview()}
        {renderError()}

        <Navigation
          state={isIntroView ? 'instruction' : state}
          disableNext={isNextDisabled && !isSkipShown}
          disablePrevious={isPreviousDisabled}
          showSkip={isSkipShown}
          hidePrevious={isBackHidden}
          submitting={isSubmitting}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />

        {/* Fancy wavy illustrations */}
        <HeroIllustrationTop />
        <HeroIllustrationBottom />
      </article>

      <Timeout state={state} onTimeout={handleTimeout} />
    </main>
  );
};
