import { ChangeEvent, FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { CheckSquare, Square, CheckCircle, Circle } from 'phosphor-react';
import css from './Checkbox.module.scss';

export interface RadioProps {
  radio: true;
  name: string;
  value: string;
}

export interface CheckboxProps {
  radio?: false;
}

interface CommonProps {
  radio?: boolean;
  name?: string;
  value?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

type CombinedProps = (CheckboxProps | RadioProps) & CommonProps;

const typeToStateIcon = {
  checkbox: {
    checked: <CheckSquare weight="fill" size={28} />,
    unchecked: <Square weight="bold" size={28} />,
  },
  radio: {
    checked: <CheckCircle weight="fill" size={28} />,
    unchecked: <Circle weight="bold" size={28} />,
  },
};

export const Checkbox: FC<CombinedProps> = ({
  radio = false,
  className = null,
  checked,
  disabled = false,
  onChange,
  children,
  ...other
}) => {
  const type = radio ? 'radio' : 'checkbox';
  const state = checked ? 'checked' : 'unchecked';

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      if (onChange) onChange(event.target.checked);
    },
    [disabled, onChange]
  );

  return (
    <label className={cn('checkbox', css.checkbox, checked && 'checked', checked && css.checked, disabled && css.disabled, className)}>
      {children}
      <input type={type} checked={checked} onChange={handleChange} disabled={disabled} {...other} />

      {typeToStateIcon[type][state]}
    </label>
  );
};
