import { FC, useCallback } from 'react';
import { Text } from '../Text/Text';
import css from './Features.module.scss';

export interface Feature {
  id: string;
  title: string | JSX.Element;
  value?: string | number | JSX.Element;
  icon: JSX.Element;
}

export interface FeaturesProps {
  items: Feature[];
}

export const Features: FC<FeaturesProps> = ({ items }) => {
  const renderFeature = useCallback(
    ({ id, title, value, icon }: Feature) => (
      <li key={id}>
        <icon.type size={28} {...icon.props} aria-hidden />
        <Text>
          {value && <b>{value}</b>} {title}
        </Text>
      </li>
    ),
    []
  );

  return <ul className={css.features}>{items.map(renderFeature)}</ul>;
};
