import { QuestionDocument, QuestionType } from './StudyDocument';
import { AnswerDocument, ChooseMultipleAnswerDocument } from './AnswerDocument';

/** Returns true or false indicating should the comment field be visible */
export const getIsCommentShown = (question: QuestionDocument, answer: AnswerDocument | null) => {
  const { questionTypeID, isCommentEnabled, lastOptionRequiresComment } = question;

  if (questionTypeID === QuestionType.FREE_TEXT) return false;

  // Last option requires comment
  if (isCommentEnabled && lastOptionRequiresComment) {
    if (!answer) return false;
    if (![QuestionType.CHOOSE_ONE, QuestionType.CHOOSE_MULTIPLE].includes(question.questionTypeID)) return false;
    if (question.options.length < 1) return false;

    const lastOption = question.options[question.options.length - 1];
    if (((answer as ChooseMultipleAnswerDocument).questionOptionIDs || []).includes(lastOption.questionOptionID)) {
      return true;
    }

    return false;
  }

  // Optional comment
  if (isCommentEnabled) return true;

  return false;
};

export const getIsCommentRequired = (question: QuestionDocument, answer: AnswerDocument | null) => {
  const { isCommentEnabled, isCommentOptional, lastOptionRequiresComment } = question;

  if (!isCommentEnabled) return false;

  // Last option requires comment
  if (lastOptionRequiresComment) {
    return getIsCommentShown(question, answer);
  }

  return !isCommentOptional;
};
