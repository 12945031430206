import React, { FC } from 'react';
import { MediaType } from 'Constants';
import { QuestionDocument } from 'common/StudyDocument';
import { ImageSlider } from './ImageSlider';
import { Link } from './Link';
import { YouTubeVideo } from './YouTubeVideo';
import css from './Media.module.scss';

export const Media: FC<Pick<QuestionDocument, 'questionID' | 'media'>> = ({ questionID, media }) => {
  const images = media.filter(({ questionMediaTypeID }) => questionMediaTypeID === MediaType.IMAGE);
  const link = media.find(({ questionMediaTypeID }) => questionMediaTypeID === MediaType.LINK);
  const youtubeVideo = media.find(({ questionMediaTypeID }) => questionMediaTypeID === MediaType.YOUTUBE_VIDEO);

  return (
    <section className={css.container}>
      {!!images.length && <ImageSlider images={images} />}
      {link && <Link link={link} />}
      {youtubeVideo && <YouTubeVideo questionID={questionID} video={youtubeVideo} />}
    </section>
  );
};
