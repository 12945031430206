import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Slider } from 'components/Slider/Slider';
import { Text } from 'components/Text/Text';
import css from './NPS.module.scss';

const nps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const initialValue = 5;

export const NPS: FC<{
  id: string;
  value: number | null;
  minLabel?: string;
  maxLabel?: string;
  disabled?: boolean;
  onChange: (optionID: number) => void;
}> = ({ id, value, minLabel = null, maxLabel = null, disabled = false, onChange }) => {
  const ariaLabel = useMemo(() => {
    if (!minLabel || !maxLabel) return;
    return `0 = ${minLabel}, 10 = ${maxLabel}`;
  }, [minLabel, maxLabel]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(event.target.value));
    },
    [onChange]
  );

  const handleSliderClick = useCallback(() => {
    if (value === null) return onChange(initialValue);
  }, [value]);

  const handleNumberClick = useCallback(
    (number: number) => {
      if (disabled) return;
      onChange(number);
    },
    [disabled, onChange]
  );

  const renderNumber = useCallback(
    (number: number) => {
      const handleClick = () => {
        handleNumberClick(number);
      };

      return (
        <Text key={number} className={cn(value === number && 'selected')} variant="label" element="span" onClick={handleClick}>
          <span>{number}</span>
        </Text>
      );
    },
    [id, value, handleNumberClick]
  );

  const position = value ?? initialValue;

  return (
    <div className={cn(css.nps, disabled && css.disabled)}>
      <div>{nps.map(renderNumber)}</div>
      {value !== null && (
        <Text className={css.label} style={{ left: `${position * 10}%`, transform: `translateX(${-50 + ((5 - position) / 5) * 21.5}%)` }}>
          <span>{position}</span>
        </Text>
      )}
      <Slider
        id={id}
        min={nps[0]}
        max={nps[nps.length - 1]}
        value={position ?? 5}
        step={1}
        aria-label={ariaLabel}
        disabled={disabled}
        onInput={handleChange} // For consistent cross-browser live update functionality
        onChange={handleChange} // For IE fallback
        onClick={handleSliderClick} // For desktop initial value click-in-place
        onTouchStart={handleSliderClick} // For mobile initial value click-in-place
      />
      {!!(minLabel || maxLabel) && (
        <Text variant="label" aria-hidden>
          <span>{minLabel}</span>
          <span className="spacer" />
          <span>{maxLabel}</span>
        </Text>
      )}
    </div>
  );
};
