import { FC } from 'react';

export const HeroIllustrationTop: FC = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 458" preserveAspectRatio="xMinYMax slice">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M720 430c-37 14-77 22-119 27-77 8-166-37-226-87l-57-48c-57-50-96-83-191-135-41-22-81-29-119-36l-8-2V0h720v430z"
      fill="currentColor"
    />
  </svg>
);

export const HeroIllustrationBottom: FC = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 514" preserveAspectRatio="xMinYMin slice">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M720 495v19H0V1c25-1 50-1 76 1 77 7 156 69 205 129l47 58c47 60 78 100 162 169 37 30 73 45 107 59 43 18 84 35 123 78z"
      fill="currentColor"
    />
  </svg>
);
