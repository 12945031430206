import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { QuestionType, OptionDocument, QuestionDocument } from 'common/StudyDocument';
import {
  ChooseMultipleAnswerDocument,
  CountAnswerDocument,
  NPSAnswerDocument,
  RankAnswerDocument,
  StarRatingAnswerDocument,
} from 'common/AnswerDocument';
import { getIsCommentRequired, getIsCommentShown } from 'common/commentField';
import { useQuestionLogic } from 'logic/useQuestionLogic';
import { Rating } from 'components/Rating/Rating';
import { Textarea } from 'components/Textarea/Textarea';
import { Help } from 'components/Help/Help';
import { NPS } from '../NPS/NPS';
import { Option } from '../Option/Option';
import { Count } from '../Count/Count';
import { Rank } from '../Rank/Rank';

export const useQuestion = (question: QuestionDocument) => {
  const { questionID, questionTypeID } = question;

  const { value, handleValueChange, handleCommentChange, handleRankSwap, rankAnimationKey, isVideoWatched, isOptionDisabled } =
    useQuestionLogic(question);

  const { isCommentShown, isCommentRequired } = useMemo(
    () => ({
      isCommentShown: getIsCommentShown(question, value),
      isCommentRequired: getIsCommentRequired(question, value),
    }),
    [question, value]
  );

  const renderOption = useCallback(
    ({ questionOptionID, text }: OptionDocument) => {
      return (
        <Option
          key={questionOptionID}
          questionID={questionID}
          questionTypeID={questionTypeID}
          optionID={questionOptionID}
          title={text}
          selected={(value as ChooseMultipleAnswerDocument)?.questionOptionIDs || []}
          disabled={isOptionDisabled}
          onChange={handleValueChange}
        />
      );
    },
    [questionID, questionTypeID, value, isOptionDisabled, handleValueChange]
  );

  const renderContent = useCallback(() => {
    switch (questionTypeID) {
      case QuestionType.STAR_RATING:
        return (
          <Rating
            id={`question-${questionID}`}
            value={(value as StarRatingAnswerDocument)?.number ?? null}
            disabled={isOptionDisabled}
            onChange={handleValueChange}
          />
        );
      case QuestionType.NPS:
        return (
          <NPS
            id={`question-${questionID}`}
            value={(value as NPSAnswerDocument)?.number ?? null}
            minLabel={question.descriptionMinimum}
            maxLabel={question.descriptionMaximum}
            disabled={isOptionDisabled}
            onChange={handleValueChange}
          />
        );
      case QuestionType.FREE_TEXT:
        return (
          <Textarea
            answer
            mandatory
            value={value?.text || ''}
            min={question.minLength ?? null}
            max={question.maxLength ?? null}
            disabled={isOptionDisabled}
            onChange={handleCommentChange}
          />
        );
      case QuestionType.COUNT:
        return (
          <Count
            id={`question-${questionID}`}
            min={question.minValue ?? 0}
            max={question.maxValue ?? 100}
            unit={question.unit}
            decimals={question.decimals || 0}
            value={(value as CountAnswerDocument)?.number ?? null}
            disabled={isOptionDisabled}
            onChange={handleValueChange}
          />
        );
      case QuestionType.RANK:
        return (
          <Rank
            id={`question-${questionID}`}
            options={question.options}
            selected={(value as RankAnswerDocument)?.questionOptionIDs || []}
            disabled={isOptionDisabled}
            amountToRank={question.maxValue ?? question.options.length}
            animationKey={rankAnimationKey}
            onChange={handleValueChange}
            onSwap={handleRankSwap}
          />
        );
      default:
        return (question.options || []).map(renderOption);
    }
  }, [questionID, questionTypeID, value, isOptionDisabled, handleValueChange, renderOption]);

  const getHelpTexts = useCallback(() => {
    const { questionTypeID, minValue, maxValue, options } = question;

    const isMin = !isNaN(minValue ?? NaN);
    const isMax = !isNaN(maxValue ?? NaN);

    const getChooseMultipleHelpText = () => {
      if (isMin && isMax) {
        if (minValue === maxValue) {
          return (
            <FormattedMessage
              id="question.chooseMultiple.specificHelpText"
              defaultMessage="You can pick {count, plural, =0 {# answers} =1 {# answer} other {# answers}}"
              values={{ count: minValue }}
            />
          );
        }

        if (minValue === 1 && maxValue === options.length) {
          <FormattedMessage id="question.chooseMultiple.anyHelpText" defaultMessage="You can pick multiple answers" />;
        }

        return (
          <FormattedMessage
            id="question.chooseMultiple.rangeHelpText"
            defaultMessage="You can pick between {min} and {max, plural, =0 {# answers} =1 {# answer} other {# answers}}"
            values={{ min: minValue, max: maxValue }}
          />
        );
      }

      if (maxValue === 1) {
        return <FormattedMessage id="question.chooseMultiple.oneHelpText" defaultMessage="You can pick one answer" />;
      }

      if (isMin && minValue !== 1) {
        return (
          <FormattedMessage
            id="question.chooseMultiple.minHelpText"
            defaultMessage="You must pick at least {min, plural, =0 {# answers} =1 {# answer} other {# answers}}"
            values={{ min: minValue }}
          />
        );
      }

      if (isMax && maxValue !== options.length) {
        return (
          <FormattedMessage
            id="question.chooseMultiple.maxHelpText"
            defaultMessage="You can pick up to {max, plural, =0 {# answers} =1 {# answer} other {# answers}}"
            values={{ max: minValue }}
          />
        );
      }

      return <FormattedMessage id="question.chooseMultiple.genericHelpText" defaultMessage="You can pick multiple answers" />;
    };

    const getRankHelpText = () => {
      if (isMin && isMax) {
        if (minValue === maxValue) {
          return (
            <FormattedMessage
              id="question.rank.specificHelpText"
              defaultMessage="You must rank {count, plural, =0 {# items} =1 {# item} other {# items}}"
              values={{ count: minValue }}
            />
          );
        }

        if (minValue === 1 && maxValue === options.length) {
          <FormattedMessage id="question.rank.anyHelpText" defaultMessage="You can rank multiple items" />;
        }

        return (
          <FormattedMessage
            id="question.rank.rangeHelpText"
            defaultMessage="You can rank between {min} and {max, plural, =0 {# items} =1 {# item} other {# items}}"
            values={{ min: minValue, max: maxValue }}
          />
        );
      }

      if (maxValue === 1) {
        return <FormattedMessage id="question.rank.oneHelpText" defaultMessage="You can rank one item" />;
      }

      if (isMin && minValue !== 1) {
        return (
          <FormattedMessage
            id="question.rank.minHelpText"
            defaultMessage="You must rank at least {min, plural, =0 {# items} =1 {# item} other {# items}}"
            values={{ min: minValue }}
          />
        );
      }

      if (isMax && maxValue !== options.length) {
        return (
          <FormattedMessage
            id="question.rank.maxHelpText"
            defaultMessage="You can rank up to {max, plural, =0 {# items} =1 {# item} other {# items}}"
            values={{ max: minValue }}
          />
        );
      }

      return null;
    };

    switch (questionTypeID) {
      case QuestionType.CHOOSE_ONE:
        return <FormattedMessage id="question.chooseOne.helpText" defaultMessage="You can pick one answer" />;

      case QuestionType.CHOOSE_MULTIPLE:
        return getChooseMultipleHelpText();

      case QuestionType.PHOTO:
        // TODO: Implement photo rule texts
        return null;

      case QuestionType.RANK:
        let defaultRankHelper = (
          <FormattedMessage
            id="question.rank.helpText"
            defaultMessage="Rank items by checking them in the order they should be sorted by"
          />
        );
        return [defaultRankHelper, getRankHelpText()];

      case QuestionType.FREE_TEXT:
      case QuestionType.COUNT:
      case QuestionType.STAR_RATING:
      case QuestionType.NPS:
        return null;
    }
  }, [question, value]);

  const renderHelpText = useCallback(
    () =>
      [getHelpTexts()]
        .flat()
        .filter(item => !!item)
        .map(item => <Help key={item?.props.id}>{item}</Help>),
    [getHelpTexts]
  );

  return {
    value,
    isCommentShown,
    isCommentRequired,
    isVideoWatched,
    isOptionDisabled,
    handleValueChange,
    handleCommentChange,
    renderContent,
    renderHelpText,
  };
};
