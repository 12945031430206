import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChatsCircle, Hourglass } from 'phosphor-react';
import { PanelProvider } from 'SafeConstants';
import type { StudyDocument } from 'common/StudyDocument';
import { Feature, Features } from 'components/Features/Features';
import { Text } from 'components/Text/Text';
import { Navigation } from 'components/Study/Navigation/Navigation';
import { HeroIllustrationBottom, HeroIllustrationTop } from './HeroIllutrations';
import css from './Hero.module.scss';

// Left side of the screen with black background
export const Hero: FC<{ study: StudyDocument; onQuit: () => void; onNext: () => void }> = ({ study, onQuit, onNext }) => {
  const { estimatedTime, questionCount, instructions } = study;

  const featureItems = useMemo<Feature[]>(
    () => [
      {
        id: 'questions',
        title: (
          <FormattedMessage
            id="hero.totalQuestionCountText"
            defaultMessage="{count, plural, =0 {questions} =1 {question} other {questions}}"
            values={{ count: questionCount || 0 }}
          />
        ),
        value: questionCount || 0,
        icon: <ChatsCircle />,
      },
      {
        id: 'time',
        title: (
          <FormattedMessage
            id="hero.estimatedDurationText"
            defaultMessage="{minutes, plural, =0 {minutes} =1 {minute} other {minutes}}"
            values={{ minutes: estimatedTime }}
            description="Estimated time in minutes it takes to do this study"
          />
        ),
        value: estimatedTime || 0,
        icon: <Hourglass />,
      },
    ],
    [questionCount, estimatedTime]
  );

  const isDescriptionVisible = !!instructions;
  const isBackHidden = study.panelProviderID === PanelProvider.UNIVERSAL_LINK;

  return (
    <article className={css.intro}>
      <div>
        <div>
          <img src="/crowst_logo.svg" alt="crowst logo" width={128} height={28} />

          <Text variant="hero">{study.name}</Text>
          {isDescriptionVisible && <Text variant="subheader">{study.description}</Text>}

          <Features items={featureItems} />
        </div>
      </div>

      {/* For mobile two screen layout navigation */}
      <Navigation state="intro" hidePrevious={isBackHidden} onPrevious={onQuit} onNext={onNext} />

      {/* Fancy wavy illustrations */}
      <HeroIllustrationTop />
      <HeroIllustrationBottom />
    </article>
  );
};
