import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useSessionState } from 'logic/sessionStore';
import { Study } from '../components/Study/Study';

export default function Home({ messages }: { messages: { [lang: string]: { [id: string]: string } } }) {
  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    if (isBrowser) useSessionState.setState({ translations: messages });
  }, [isBrowser, messages]);

  return <Study />;
}

export const getStaticProps: GetStaticProps = async context => {
  const FormData = require('form-data');
  const { config } = require('config');

  const formData = new FormData();
  formData.append('api_token', config.poeditor.token);
  formData.append('id', config.poeditor.id);

  const {
    result: { languages },
  } = await fetch('https://api.poeditor.com/v2/languages/list', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  }).then(response => response.json());

  const messages: { [lang: string]: { [id: string]: string } } = {};

  for (const language of languages) {
    const formData = new FormData();
    formData.append('api_token', config.poeditor.token);
    formData.append('id', config.poeditor.id);
    formData.append('language', language.code);

    const {
      result: { terms },
    } = await fetch('https://api.poeditor.com/v2/terms/list', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    }).then(response => response.json());

    messages[language.code] = terms.reduce(
      (data: { [id: string]: string }, { term: id, translation }: { term: string; translation: { content: string } }) => ({
        ...data,
        [id]: translation.content,
      }),
      {}
    );
  }

  return {
    props: {
      messages,
    },
    revalidate: 1,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: true };
};
