import { PropsWithChildren, forwardRef } from 'react';
import cn from 'classnames';
import { Spinner } from 'phosphor-react';
import css from './Button.module.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'filled';

export interface ButtonProps {
  id?: string;
  variant?: ButtonVariant;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  ({ id, variant = 'primary', className = null, loading = false, disabled = false, onClick, children }, ref) => {
    return (
      <button
        id={id}
        ref={ref}
        className={cn(css.button, loading && 'loading', loading && css.loading, variant, className)}
        disabled={loading || disabled}
        onClick={onClick}
      >
        {children}
        {loading && <Spinner className={css.spinner} size={28} />}
      </button>
    );
  }
);
