import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StudyStateString } from './useStudy';

export const useError = (state: StudyStateString) => {
  const invalidTitle = <FormattedMessage id="error.invalidTitleText" defaultMessage="Unavailable" />;
  const invalidSubtitle = [
    <FormattedMessage id="error.invalidInfoText" defaultMessage="This study is no longer available, or the URL for it is invalid." />,
    <FormattedMessage id="error.invalidGuideText" defaultMessage="If you believe this is incorrect, please try again later." />,
  ];

  const incompatibleTitle = <FormattedMessage id="error.incompatibleTitleText" defaultMessage="Sorry" />;
  const incompatibleSubtitle = [
    <FormattedMessage
      id="error.incompatibleInfoText"
      defaultMessage="Unfortunately, you are not allowed to answer to this study. The response limit could be full, or you might not match the demographic."
    />,
  ];

  const rejectedTitle = <FormattedMessage id="error.rejectedTitleText" defaultMessage="Sorry" />;
  const rejectedSubtitle = [
    <FormattedMessage
      id="error.rejectedInfoText"
      defaultMessage="Unfortunately, you are not allowed to answer to this study. The response limit could be full, or you might not match the demographic."
    />,
  ];

  const timeoutTitle = <FormattedMessage id="error.timeoutTitleText" defaultMessage="Timed Out" />;
  const timeoutSubtitle = [
    <FormattedMessage
      id="error.timeoutInfoText"
      defaultMessage="Unfortunately you ran out of time. Your response could not be recorded."
    />,
  ];

  const { title, subtitle } = useMemo(() => {
    if (state === 'error-incompatible') return { title: incompatibleTitle, subtitle: incompatibleSubtitle };
    if (state === 'reject-study') return { title: rejectedTitle, subtitle: rejectedSubtitle };
    if (state === 'error-timeout') return { title: timeoutTitle, subtitle: timeoutSubtitle };
    return { title: invalidTitle, subtitle: invalidSubtitle };
  }, [state]);

  return { title, subtitle };
};
