import { FC, HTMLProps } from 'react';
import cn from 'classnames';
import css from '../Input/Input.module.scss';

export interface SelectProps extends HTMLProps<HTMLSelectElement> {
  options: {
    id: string | number;
    label: string;
  }[];
}

export const Select: FC<SelectProps> = ({ id, value, label = null, className, options, ...other }) => {
  return (
    <div className={cn(css.input, className, 'input', 'select')}>
      {label && (
        <label htmlFor={id} className={css.label}>
          {label}
        </label>
      )}
      <select id={id} {...other}>
        <option disabled selected value={0}></option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
