import { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { Text, TextProps } from './Text';
import css from './Text.module.scss';

export const Flip: FC<TextProps> = ({ children, ...props }) => {
  const [current, setCurrent] = useState<ReactNode>(children);
  const [previous, setPrevious] = useState<ReactNode>(null);

  const isPreviousSet = previous && previous !== null && previous !== current;

  useEffect(() => {
    setCurrent(previous => {
      setPrevious(previous);
      return children;
    });

    const animationEndTimeout = setTimeout(() => {
      setPrevious(null);
    }, 1000);

    return () => clearTimeout(animationEndTimeout);
  }, [children]);

  return (
    <div className={cn(css.flip, isPreviousSet && css.flipAnimate)}>
      <Text {...props}>{current}</Text>
      {isPreviousSet && (
        <div className={cn(css.flipContainer)} aria-hidden>
          <Text {...props}>{current}</Text>
          <Text {...props}>{previous}</Text>
        </div>
      )}
    </div>
  );
};
