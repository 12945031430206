import { CSSProperties, FC } from 'react';
import cn from 'classnames';
import { Info, WarningCircle } from 'phosphor-react';
import { Text } from 'components/Text/Text';
import css from './Banner.module.scss';

export interface BannerProps {
  type?: 'info' | 'error';
  icon?: JSX.Element | null;
  button?: JSX.Element;
  style?: CSSProperties;
}

export const Banner: FC<BannerProps> = ({ type = 'info', icon, button = null, style = {}, children, ...other }) => {
  let visibleIcon: JSX.Element | null = null;
  if (type === 'info') visibleIcon = <Info size={28} weight="bold" />;
  if (type === 'error') visibleIcon = <WarningCircle size={28} weight="fill" />;
  if (icon || icon === null) visibleIcon = icon;

  const isError = type === 'error';

  return (
    <section className={cn(type, css.banner, isError && css.error)} {...other}>
      <div className={css.content}>
        {visibleIcon}
        <Text variant="body">{children}</Text>
      </div>
      {button}
    </section>
  );
};
