import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import css from '../Question/Question.module.scss';
import color from 'styles/color.module.scss';

const textHeight = 13;

export const QuestionSkeleton: FC = () => (
  <article className={css.question}>
    <form className={css.content} aria-label={`instructions`}>
      <ContentLoader viewBox="0 0 380 116" backgroundColor={color.beige} foregroundColor={color.loading}>
        <rect x="0" y="12" rx="2" ry="2" width="280" height={textHeight} />
        <rect x="0" y={12 + textHeight + 4} rx="2" ry="2" width="360" height={textHeight} />
        <rect x="0" y={12 + (textHeight + 4) * 2} rx="2" ry="2" width="120" height={textHeight} />

        <rect x="0" y={12 + 40 + (textHeight + 4) * 3} rx="2" ry="2" width="240" height={textHeight} />
      </ContentLoader>
    </form>
  </article>
);
