import { QuestionDocument, QuestionType } from './StudyDocument';
import {
  AnswerDocument,
  ChooseMultipleAnswerDocument,
  ChooseOneAnswerDocument,
  CountAnswerDocument,
  FreeTextAnswerDocument,
  NPSAnswerDocument,
} from './AnswerDocument';
import { getIsCommentRequired } from './commentField';

export const starRatingOptions = [1, 2, 3, 4, 5];
export const npsOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const minCommentLength = 4;
export const maxCommentLength = 300;

export const getDecimals = (value: number) => {
  if (value % 1 != 0) return value.toString().split('.')[1].length;
  return 0;
};

export const getIsQuestionAnswered = (question: QuestionDocument | null, answer: AnswerDocument | null) => {
  if (!question) return false;
  if (!answer) return false;

  const { questionTypeID } = question;

  const isFreeText = questionTypeID === QuestionType.FREE_TEXT;
  const isCommentRequired = getIsCommentRequired(question, answer);
  if (!isFreeText && (isCommentRequired || (answer.text && answer.text.length > 0))) {
    if (!answer.text) return false;
    if (answer.text.length < minCommentLength) return false;
    if (answer.text.length > maxCommentLength) return false;
  }

  let isAnswerValid = false;

  switch (questionTypeID) {
    case QuestionType.CHOOSE_ONE:
      const { questionOptionIDs: singleOptions } = answer as ChooseOneAnswerDocument;
      if (!singleOptions) break;
      if (singleOptions.length !== 1) break;
      isAnswerValid = true;
      break;

    case QuestionType.CHOOSE_MULTIPLE:
      const { questionOptionIDs: multipleOptions } = answer as ChooseMultipleAnswerDocument;
      if (!multipleOptions) break;
      if (multipleOptions.length === 0) break;
      if (!isNaN(question.minValue ?? NaN) && (question.minValue as number) > multipleOptions.length) break;
      if (!isNaN(question.maxValue ?? NaN) && (question.maxValue as number) < multipleOptions.length) break;
      isAnswerValid = true;
      break;

    case QuestionType.FREE_TEXT:
      const textLength = ((answer as FreeTextAnswerDocument).text || '').length;
      if (!isNaN(question.minLength ?? NaN) && (question.minLength as number) > textLength) break;
      if (!isNaN(question.maxLength ?? NaN) && (question.maxLength as number) < textLength) break;
      isAnswerValid = true;
      break;

    case QuestionType.COUNT:
      const countValue = (answer as CountAnswerDocument).number;
      if (countValue === undefined) break;
      const countDecimals = getDecimals(countValue);
      if (!isNaN(question.minValue ?? NaN) && (question.minValue as number) > countValue) break;
      if (!isNaN(question.maxValue ?? NaN) && (question.maxValue as number) < countValue) break;
      if (!isNaN(question.decimals ?? NaN) && (question.decimals as number) < countDecimals) break;
      isAnswerValid = true;
      break;

    case QuestionType.STAR_RATING:
      const starValue = (answer as CountAnswerDocument).number;
      if (!starRatingOptions.includes(starValue)) break;
      isAnswerValid = true;
      break;

    case QuestionType.PHOTO:
      break;

    case QuestionType.RANK:
      const { questionOptionIDs: rankOptions } = answer as ChooseMultipleAnswerDocument;
      if (!rankOptions) break;
      if (rankOptions.length === 0) break;
      if (!isNaN(question.minValue ?? NaN) && (question.minValue as number) > rankOptions.length) break;
      if (!isNaN(question.maxValue ?? NaN) && (question.maxValue as number) < rankOptions.length) break;
      isAnswerValid = true;
      break;

    case QuestionType.NPS:
      const npsValue = (answer as NPSAnswerDocument).number;
      if (!npsOptions.includes(npsValue)) break;
      isAnswerValid = true;
      break;
  }

  return isAnswerValid;
};
