import { FormattedMessage } from 'react-intl';
import { StudyStateString } from 'logic/useStudy';

const thanksTitle = (
  <>
    <FormattedMessage id="overview.doneTitleText" defaultMessage="Thanks" description="Will include an exclamation mark at the end" />
    <span>!</span>
  </>
);

export const useOverview = (state: StudyStateString) => {
  if (state == 'done') {
    // Done screen
    return {
      title: thanksTitle,
      subtitle: [
        <FormattedMessage id="overview.doneInfoText" defaultMessage="Your answer has been submitted." />,
        <FormattedMessage id="overview.doneThanksText" defaultMessage="We really appreciate your feedback." />,
      ],
    };
  } else if (state == 'submit-study') {
    // Loading state after pressing submit
    return {
      title: thanksTitle,
      subtitle: [
        <FormattedMessage
          id="overview.submitInfoText"
          defaultMessage={
            'You have reached the end. Press "Submit" to record your answers, or use the "Previous" button to review & modify them.'
          }
        />,
        <FormattedMessage id="overview.submitThanksText" defaultMessage="We really appreciate your feedback." />,
      ],
    };
  } else {
    // Review study screen (where user should press submit)
    return {
      title: <FormattedMessage id="overview.reviewTitleText" defaultMessage="Almost there…" />,
      subtitle: [
        <FormattedMessage
          id="overview.reviewInfoText"
          defaultMessage={
            'You have reached the end. Press "Submit" to record your answers, or use the "Previous" button to review & modify them.'
          }
        />,
        <FormattedMessage id="overview.reviewThanksText" defaultMessage="We really appreciate your feedback." />,
      ],
    };
  }
};
