import create from 'zustand';
import { combine } from 'zustand/middleware';
import { AttributeGender } from 'Constants';

export interface AttributeState {
  age: number;
  gender: AttributeGender;
  postalCode: string;

  attributeError: 'age' | 'gender' | 'postalCode' | null;
}

const initialState: AttributeState = {
  age: 0,
  gender: 0,
  postalCode: '',

  attributeError: null,
};

export const useAttributeState = create(
  combine(initialState, (set, get) => ({
    setError: (error: AttributeState['attributeError']) => set({ attributeError: error }),
    set,
  }))
);
