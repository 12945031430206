import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AttributeGender } from 'Constants';
import { StudyDocument } from 'common/StudyDocument';
import { useAttributeState } from 'logic/attributeStore';
import { Banner } from 'components/Banner/Banner';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';
import { Select } from 'components/Select/Select';
import questionCss from '../Question/Question.module.scss';
import css from './Instructions.module.scss';

export interface InstructionsProps {
  study: StudyDocument;
  onAcknowledgement: (checked: boolean) => void;
}

export const Instructions: FC<InstructionsProps> = ({ study, onAcknowledgement }) => {
  const intl = useIntl();
  const { description, instructions, requiredAttributes } = study;
  const { age, gender, postalCode, attributeError, set } = useAttributeState();
  const [acknowledgement, setAcknowledgement] = useState(false);

  const title = instructions || description;

  const isAgeShown = requiredAttributes.includes('age');
  const isGenderShown = requiredAttributes.includes('gender');
  const isPostalCodeShown = requiredAttributes.includes('postalCode');

  const ageLabel = intl.formatMessage({ id: 'instruction.ageInput', defaultMessage: 'Age' });
  const genderLabel = intl.formatMessage({ id: 'instruction.genderInput', defaultMessage: 'Gender' });
  const postalCodeLabel = intl.formatMessage({ id: 'instruction.postalCodeInput', defaultMessage: 'Postal Code' });

  const genderOptions = useMemo(
    () => [
      { id: AttributeGender.MALE, label: intl.formatMessage({ id: 'instruction.genderMaleOption', defaultMessage: 'Male' }) },
      { id: AttributeGender.FEMALE, label: intl.formatMessage({ id: 'instruction.genderFemaleOption', defaultMessage: 'Female' }) },
      { id: AttributeGender.OTHER, label: intl.formatMessage({ id: 'instruction.genderOtherOption', defaultMessage: 'Other' }) },
    ],
    [intl.locale]
  );

  const handleAgeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      set({ age: parseInt(value) || 0 });
    },
    [set]
  );

  const handleGenderChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      set({ gender: parseInt(value) || AttributeGender.MALE });
    },
    [set]
  );

  const handlePostalCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      set({ postalCode: value });
    },
    [set]
  );

  const handleAcknowledgementChange = useCallback(
    (checked: boolean) => {
      setAcknowledgement(checked);
      onAcknowledgement(checked);
    },
    [setAcknowledgement]
  );

  return (
    <article className={questionCss.question}>
      <form className={questionCss.content} aria-label={`instructions`}>
        <Text className={questionCss.title}>{title}</Text>

        <div className={css.answers}>
          {isAgeShown && (
            <Input
              id="age-input"
              type="number"
              name="age"
              label={ageLabel}
              value={age || ''}
              min={13}
              max={120}
              onChange={handleAgeChange}
            />
          )}
          {isGenderShown && (
            <Select
              id="gender-input"
              name="gender"
              label={genderLabel}
              options={genderOptions}
              value={gender}
              onChange={handleGenderChange}
            />
          )}
          {isPostalCodeShown && (
            <Input id="postalCode-input" name="postalCode" label={postalCodeLabel} value={postalCode} onChange={handlePostalCodeChange} />
          )}

          <Checkbox checked={acknowledgement} onChange={handleAcknowledgementChange}>
            <FormattedMessage id="instruction.acknowledgementCheckbox" defaultMessage="I have read the study description" />
          </Checkbox>

          {attributeError === 'age' && (
            <Banner type="error">
              <FormattedMessage id="instruction.ageError" defaultMessage="Check age" />
            </Banner>
          )}
          {attributeError === 'gender' && (
            <Banner type="error">
              <FormattedMessage id="instruction.genderError" defaultMessage="Check gender" />
            </Banner>
          )}
          {attributeError === 'postalCode' && (
            <Banner type="error">
              <FormattedMessage id="instruction.postalCodeError" defaultMessage="Check postal code" />
            </Banner>
          )}
        </div>
      </form>
    </article>
  );
};
