import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { Text } from 'components/Text/Text';
import { Help } from 'components/Help/Help';
import css from './Textarea.module.scss';

export interface TextareaProps {
  value: string;
  mandatory?: boolean;
  answer?: boolean;
  min?: number | null;
  max?: number | null;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const Textarea: FC<TextareaProps> = ({
  value = '',
  mandatory = false,
  answer = false,
  min = null,
  max = null,
  disabled = false,
  onChange,
}) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(false);

  const answerPlaceholder = intl.formatMessage({ id: 'question.comment.answerPlaceholder', defaultMessage: 'Type your answer…' });
  const requiredPlaceholder = intl.formatMessage({ id: 'question.comment.commentPlaceholder', defaultMessage: 'Type a comment…' });
  const optionalPlaceholder = intl.formatMessage({
    id: 'question.comment.optionalCommentPlaceholder',
    defaultMessage: 'Type an optional comment…',
  });

  const isMin = min ?? null !== null;
  const isMax = max ?? null !== null;
  const isRequirementsVisible = !!(isMin || isMax);

  let isMinPassed = true;
  let isMaxPassed = true;
  if (isMin && value.length < (min as number)) isMinPassed = false;
  if (isMax && value.length > (max as number)) isMaxPassed = false;

  let isRequirementsPassed = (isMinPassed && isMaxPassed) || (!mandatory && value.length === 0);
  if (!touched) isRequirementsPassed = true;

  const placeholder = useMemo(() => {
    if (answer) return answerPlaceholder;
    if (mandatory) return requiredPlaceholder;
    return optionalPlaceholder;
  }, [mandatory, answer]);

  const requiredHelpText = <FormattedMessage id="question.comment.requiredHelpText" defaultMessage="Comment is mandatory" />;
  const optionalHelpText = <FormattedMessage id="question.comment.optionalHelpText" defaultMessage="Comment is optional" />;

  const helpText = useMemo(() => {
    if (answer) return null;
    if (mandatory) return requiredHelpText;
    return optionalHelpText;
  }, [mandatory, answer]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value), [onChange]);
  const handleBlur = useCallback(() => setTouched(true), []);

  return (
    <div className={cn(css.container, disabled && css.disabled)}>
      <div>
        <textarea
          placeholder={placeholder}
          value={value}
          className={cn(css.textarea, !answer && 'comment')}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {isRequirementsVisible && (
          <div className={css.requirements}>
            {!isRequirementsPassed && !isMinPassed && (
              <>
                <Text
                  variant="fineprint"
                  element="label"
                  className={cn(css.requirement, css.requirementsMin, !isRequirementsPassed && !isMinPassed && css.requirementsError)}
                >
                  <FormattedMessage
                    id="question.comment.characterCountMinError"
                    defaultMessage="Minimum number of characters is {min}"
                    values={{ current: value.length, min: min ?? 0, max }}
                    description="Shown when text length is under the minimum requirement. Usable variables include {current}, {min} & {max}."
                  />
                </Text>
                <span style={{ flex: 1 }} />
              </>
            )}
            <Text variant="fineprint" element="label" className={cn(css.requirement, !isRequirementsPassed && css.requirementsError)}>
              <FormattedMessage
                id="question.comment.characterCountText"
                defaultMessage="{current} / {max}"
                values={{ current: value.length, min: min ?? 0, max }}
                description="Always shown under a textarea. Usable variables include {current}, {min} & {max}."
              />
            </Text>
          </div>
        )}
      </div>
      {helpText && <Help>{helpText}</Help>}
    </div>
  );
};
