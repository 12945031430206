import { FC, useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { ArrowLeft, ArrowRight, X } from 'phosphor-react';
import { Button } from 'components/Button/Button';
import css from './Media.module.scss';

import 'swiper/css';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination]);

const SwiperControls: FC<{
  modal: boolean;
  onButton: (state: { next?: HTMLButtonElement | null; prev?: HTMLButtonElement | null }) => void;
  onClose: () => void;
}> = ({ modal, onButton, onClose }) => {
  const handlePrevButtonRef = useCallback(
    (element: HTMLButtonElement) => {
      onButton({ prev: element });
    },
    [onButton]
  );

  const handleNextButtonRef = useCallback(
    (element: HTMLButtonElement) => {
      onButton({ next: element });
    },
    [onButton]
  );

  return (
    <div className={css.controls}>
      <Button className="close" variant="secondary" onClick={onClose} disabled={!modal}>
        <X size={28} />
      </Button>
      <Button ref={handlePrevButtonRef} className="left" variant="secondary">
        <ArrowLeft size={28} />
      </Button>
      <Button ref={handleNextButtonRef} className="right" variant="secondary">
        <ArrowRight size={28} />
      </Button>
    </div>
  );
};

export const Swiper: FC<{
  images: { url: string; caption: string }[];
  initialSlide: number;
  modal: boolean;
  onSlide: (slide: number) => void;
  onOpen: () => void;
  onClose: () => void;
}> = ({ images, initialSlide, modal, onSlide, onOpen, onClose }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [navigationButtons, setNavigationButtons] = useState<{ next: HTMLButtonElement | null; prev: HTMLButtonElement | null }>({
    next: null,
    prev: null,
  });

  useEffect(() => {
    if (!modal) return;

    const handleKey = (event: KeyboardEvent) => {
      const swiper = swiperRef.current;
      if (event.key === 'ArrowRight' && swiper) return swiper.slideNext();
      if (event.key === 'ArrowLeft' && swiper) return swiper.slidePrev();
      if (event.key === 'Escape') return onClose();
    };

    document.addEventListener('keydown', handleKey, false);

    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [modal]);

  const renderMedia = useCallback(({ url, caption }: { url: string; caption: string }, index: number) => {
    const id = `image-${index}`;
    const labelId = `${id}-caption`;

    return (
      <SwiperSlide key={id} className={css.image}>
        <img aria-labelledby={labelId} src={url} />
        <figcaption id={labelId} className={css.caption}>
          <span>{caption}</span>
        </figcaption>
      </SwiperSlide>
    );
  }, []);

  const handleSlideChange = useCallback((swiper: SwiperCore) => onSlide(swiper.activeIndex), [onSlide]);

  const handleSetNavigationButton = useCallback(
    (data: { next?: HTMLButtonElement | null; prev?: HTMLButtonElement | null }) => setNavigationButtons(state => ({ ...state, ...data })),
    []
  );

  return (
    <div className={css.media}>
      <div className={css.content}>
        <SwiperComponent
          initialSlide={initialSlide}
          pagination
          navigation={{
            nextEl: navigationButtons.next,
            prevEl: navigationButtons.prev,
          }}
          onSlideChange={handleSlideChange}
          onSwiper={swiper => {
            swiperRef.current = swiper;
            swiper.on('click', onOpen);
          }}
        >
          {images.map(renderMedia)}
        </SwiperComponent>
      </div>

      <SwiperControls modal={modal} onButton={handleSetNavigationButton} onClose={onClose} />
    </div>
  );
};
