import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { AttributeGender, PanelProvider } from 'Constants';
import { getIsQuestionAnswered } from 'common/answerLogic';
import { calculateNextQuestion } from 'common/logicRules';
import { StudyDocument, QuestionDocument, LogicRuleTarget } from 'common/StudyDocument';
import { AnswerDocument, AnswerResponseState } from 'common/AnswerDocument';
import { QueryParts, useSessionState } from './sessionStore';
import { StudyState, useStudyState } from './studyStore';
import { useAnswerState } from './answerStore';
import { useAttributeState } from './attributeStore';
import { useVideoState, VideoState } from './videoStore';

export type StudyStateString =
  | 'load-metadata' // Before metadata has loaded
  | 'do-intro'
  | 'request-study'
  | 'do-study'
  | 'review-study'
  | 'submit-study'
  | 'reject-study'
  | 'done'
  | 'error-submit'
  | 'error-invalid'
  | 'error-incompatible'
  | 'error-timeout';

/** States when the "loading" screen should show */
export const loadingStates: StudyStateString[] = ['load-metadata', 'request-study'];

/** States when the "landing" screen should show */
export const landingStates: StudyStateString[] = ['load-metadata', 'request-study'];

/** States when the "overview" screen should show */
export const overviewStates: StudyStateString[] = ['review-study', 'submit-study', 'done'];

/** States when the "error" screen should show */
export const errorStates: StudyStateString[] = ['error-invalid', 'error-incompatible', 'error-timeout', 'reject-study'];

/**
 * Parse incoming url and query parts
 */
const parseQuery = (query: ParsedUrlQuery): QueryParts | null => {
  console.log('parseQuery', query);

  if (!Array.isArray(query?.url)) return null; // Url is required

  // Get queryParams (if same key is found multiple times, only take the first)
  const queryParams: { [key: string]: string } = {};
  for (const [key, values] of Object.entries(query)) {
    if (!values || key == 'url') continue;
    queryParams[key] = Array.isArray(values) ? values[0] : values;
  }

  console.log('query ok', { url: '/' + query.url.join('/'), queryParams });

  return {
    url: '/' + query.url.join('/'),
    queryParams,
  };
};

/**
 * Submit attributes to API and get metadata back
 */
const loadMetadata = async (
  queryParams: QueryParts
): Promise<{ status: string; details: string; study?: StudyDocument; redirectUrl: string | null }> => {
  const {
    status,
    details,
    study,
    redirectUrl = null,
  } = await fetch('/api/metadata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(queryParams),
  }).then(response => response.json());

  return { status, details, study, redirectUrl };
};

/**
 * Submit final attributes to API and get response ID & questions back
 */
const startStudy = async (
  queryParams: QueryParts
): Promise<{
  status: string;
  study?: StudyDocument;
  details: string;
  surveyResponseID: number;
  redirectUrl: string | null;
  isProtected: boolean;
}> => {
  const {
    status,
    study,
    details,
    surveyResponseID,
    redirectUrl = null,
    isProtected,
  } = await fetch('/api/start', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(queryParams),
  }).then(response => response.json());

  return { status, study, details, surveyResponseID, redirectUrl, isProtected };
};

/**
 * Submit answers to the study
 */
const submitStudy = async (surveyResponseID: number, answers: AnswerDocument[], isRejected: boolean) => {
  const { status, redirectUrl } = await fetch('/api/submit', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ surveyResponseID, answers, isRejected }),
  }).then(response => response.json());
  return { status, redirectUrl };
};

const studyStateSelector = (state: StudyState) => ({
  ...state,
  //questions: state.document.questions || [],
});

export const useStudy = () => {
  const { isReady: isQueryReady } = useRouter();

  const sessionState = useSessionState();
  const { setStudyData, setStudyDataLoadError } = useStudyState();
  const { isRejected, setSkipped, setRejected, getAnswer } = useAnswerState();
  const [state, setState] = useState<StudyStateString>('load-metadata');
  const [question, setQuestion] = useState<QuestionDocument | null>(null);
  const [history, setHistory] = useState<QuestionDocument[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [isProtected, setIsProtected] = useState(false);

  const genericError = 'Something went wrong… Please try again.';

  const answer = useAnswerState(
    useCallback(
      (state: { getAnswer: (questionID: number) => AnswerDocument | null }) => getAnswer(question?.questionID || null),
      [question?.questionID]
    )
  );

  const video = useVideoState(
    useCallback(
      (state: VideoState) => (question?.questionID ? state.videoMap.get(question.questionID) : null) || null,
      [question?.questionID]
    )
  );

  const { queryParts } = sessionState;
  const { document: study, loadStudyErrorInfo } = useStudyState(studyStateSelector);
  const isQuestionAnswered = useMemo(() => getIsQuestionAnswered(question, answer), [question, answer]);
  const isVideoWatched = video ? video.isFinished : true;
  const isSkipShown = !isQuestionAnswered && (question?.isOptional || false);

  // Load study data from API
  useEffect(() => {
    // Wait until Next has populated the query parameters
    if (!isQueryReady) return;

    // Only do this once when requested
    if (state !== 'load-metadata') return;

    // We cannot trust Next.js query object here...
    // For some reason, sometimes it does not populate the object properly
    // Simulate query object here
    const query = {
      ...Object.fromEntries(new URLSearchParams(window.location.search).entries()),
      url: [window.location.pathname.replace('/', '')],
    };

    const fail = (url: string | null = null) => {
      if (url) setRedirectUrl(url);
      return setState('error-invalid');
    };

    const queryParts = parseQuery(query);
    if (!queryParts) return fail();
    sessionState.setQueryParts(queryParts);

    loadMetadata(queryParts)
      .then(({ status, details, study, redirectUrl }) => {
        if (status === 'ok') {
          if (redirectUrl) setRedirectUrl(redirectUrl);
          setStudyData({ ...study!, url: queryParts.url });
          setState('do-intro');
        } else {
          console.error('Error loading metadata', status, JSON.stringify(details));
          setStudyDataLoadError(status);
          return fail(redirectUrl);
        }
      })
      .catch(() => setState('error-invalid'));
  }, [isQueryReady, state, setStudyData]);

  // After study data has been loaded and TODO: any missing attributes asked, request to start study from API
  useEffect(() => {
    if (!queryParts) return;
    if (state === 'request-study') {
      const fail = (url: string | null = null) => {
        if (url) setRedirectUrl(url);
        return setState('error-incompatible');
      };

      const { age, gender, postalCode } = useAttributeState.getState();

      // Assign all user filled attributes if this is a universal link
      if (study!.panelProviderID === PanelProvider.UNIVERSAL_LINK) {
        if (age) queryParts.queryParams.a = `${age}`; // Why string here?
        if (gender) queryParts.queryParams.g = `${gender}`; // Why string here?
        if (postalCode) queryParts.queryParams.p = postalCode;
      }

      // Assign age user filled attribute if this is a CINT link
      if (study!.panelProviderID === PanelProvider.CINT) {
        if (age) queryParts.queryParams.a = `${age}`; // Why string here?
      }

      startStudy(queryParts!)
        .then(({ status, study, details, surveyResponseID, redirectUrl, isProtected }) => {
          if (status == 'ok') {
            if (isProtected) {
              // Check if we have already done this study
              const isAnswered = localStorage.getItem(queryParts.url) === 'ok';
              if (isAnswered) {
                return setState('error-incompatible');
              }
            }

            useStudyState.setState({ responseTimeStart: new Date() });
            sessionState.setResponse(surveyResponseID);
            setStudyData({ ...study!, url: queryParts.url });
            setQuestion(study!.questions[0]);
            setIsProtected(isProtected);
            setState('do-study');
          } else {
            console.error('Error starting study', status, JSON.stringify(details), redirectUrl);
            setStudyDataLoadError(status);
            return fail(redirectUrl);
          }
        })
        .catch(() => fail());
    }
  }, [state, sessionState.setResponse]);

  // After everything has been answered, submit answers
  useEffect(() => {
    if (state === 'submit-study' || state === 'reject-study') {
      const { surveyResponseID } = useSessionState.getState();
      const { answers: givenAnswers } = useAnswerState.getState();
      const questions = useStudyState.getState().document.questions;
      const url = useStudyState.getState().document.url;

      const fail = () => {
        if (isRejected) return;
        setState('review-study');
        setError(genericError);
      };

      if (!surveyResponseID) return fail();

      const answers = Object.values(givenAnswers);

      const answeredQuestionIDs = answers.map(({ questionID }) => questionID);
      const unansweredQuestionIDs =
        questions?.filter(({ questionID }) => !answeredQuestionIDs.includes(questionID)).map(({ questionID }) => questionID) || [];

      // If any questions have been left without an answer, mark them as "not shown"
      for (const questionID of unansweredQuestionIDs) {
        answers.push({
          questionID,
          responseStateID: AnswerResponseState.NOT_SHOWN,
        } as any);
      }

      const timeStart = Date.now();

      setError(null);

      submitStudy(surveyResponseID, answers, isRejected)
        .then(({ status, redirectUrl, details }: { status: string; redirectUrl?: string; details?: string }) => {
          console.log('submitStudy returned', status, details);

          if (redirectUrl) setRedirectUrl(redirectUrl);

          if (status !== 'ok') {
            setStudyDataLoadError(status + details?.toString());
            return fail();
          } else {
            // Save the fact that we have answered this study to local storage
            if (isProtected) localStorage.setItem(url, 'ok');

            const timePassed = Date.now() - timeStart;
            if (redirectUrl) {
              setTimeout(() => {
                console.log('Redirecting to', redirectUrl);
                window.location.replace(redirectUrl);
                if (isRejected) return;
                setState('done');
              }, Math.max(0, 7000 - timePassed));
            } else {
              // TODO: We need completed screen for Universal Link
              if (isRejected) return;
              setState('done');
            }
          }
        })
        .catch(() => {
          return fail();
        });
    }
  }, [state, isRejected, isProtected]);

  // TODO: Parse & ask for the required attributes if any are missing

  const getNextQuestion = useCallback(
    (questionID: number) => {
      const answer = getAnswer(questionID);
      return calculateNextQuestion(study.questions, questionID, answer);
    },
    [study.questions, getAnswer]
  );

  const handleClose = useCallback(() => {
    console.log('redirectUrl', redirectUrl);
    if (redirectUrl) return window.location.replace(redirectUrl);
    window.history.back();
  }, [redirectUrl]);

  const handleStart = useCallback(() => {
    if (state === 'do-intro') {
      const { document, setAttributes } = useStudyState.getState();
      const { requiredAttributes } = document;

      if (!requiredAttributes.length) return setState('request-study');

      const isAgeRequired = requiredAttributes.includes('age');
      const isGenderRequired = requiredAttributes.includes('gender');
      const isPostalCodeRequired = requiredAttributes.includes('postalCode');

      const { age, gender, postalCode, setError } = useAttributeState.getState();

      let givenAttributes: { [key: string]: string | number } = {};

      if (isAgeRequired) {
        if (!(age && age <= 130 && age >= 0)) {
          return setError('age');
        } else {
          givenAttributes.age = age;
        }
      }

      if (isGenderRequired) {
        if (!(gender && [AttributeGender.FEMALE, AttributeGender.MALE, AttributeGender.OTHER].includes(gender))) {
          return setError('gender');
        } else {
          givenAttributes.gender = gender;
        }
      }

      if (isPostalCodeRequired) {
        if (!postalCode) {
          return setError('postalCode');
        } else {
          givenAttributes.postalCode = postalCode;
        }
      }

      setError(null);
      setAttributes(givenAttributes);
      setState('request-study');
    }
  }, [state, study.questions]);

  const handleSubmit = useCallback(() => {
    if (state == 'review-study' && error == null) {
      setState('submit-study');
    }
  }, [state, error]);

  const handleNext = useCallback(() => {
    if (state == 'done') return;
    if (state === 'do-intro') return handleStart();
    if (state === 'review-study') return handleSubmit();
    if (errorStates.includes(state)) return handleClose();
    if (!question) return;

    setHistory(history => [...history, question]);

    if (isSkipShown) setSkipped(question.questionID);

    const next = getNextQuestion(question.questionID);

    // End study
    if (typeof next === 'number') {
      const isReject = next === LogicRuleTarget.REJECT_ANSWER;
      if (isReject) {
        setRejected();
        return setState('reject-study');
      }

      return setState('review-study');
    }

    setQuestion(next);
  }, [state, question, getNextQuestion, isSkipShown, handleSubmit, handleStart, handleClose, setSkipped, setHistory, setRejected]);

  const handlePrevious = useCallback(() => {
    if (study.isBackDisabled) return;

    setHistory(history => {
      const newHistory = [...history];
      const previous = newHistory.length > 0 ? newHistory.pop() || null : null;
      if (previous === null) return history;

      setQuestion(previous);
      setState('do-study'); // TODO: What if going back to landing page?

      return newHistory;
    });
  }, [study.isBackDisabled]);

  const handleTimeout = useCallback(() => {
    // Save the fact that we have answered this study to local storage
    const url = useStudyState.getState().document.url;
    if (isProtected) localStorage.setItem(url, 'ok');

    return setState('error-timeout');
  }, [isProtected]);

  return {
    state,
    error,
    loadStudyErrorInfo,
    study,
    question,
    history,
    redirectUrl,
    isQuestionAnswered,
    isVideoWatched,
    isSkipShown,
    handleStart,
    handleNext,
    handlePrevious,
    handleTimeout,
  };
};
