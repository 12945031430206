import { FC } from 'react';
import { Info } from 'phosphor-react';
import { Text } from 'components/Text/Text';
import css from './Help.module.scss';

export const Help: FC = ({ children }) => {
  return (
    <Text className={css.help} variant="fineprint">
      <Info size={16} weight="bold" /> {children}
    </Text>
  );
};
