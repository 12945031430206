import { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import YouTube from 'react-youtube';
import { MediaDocument } from 'common/StudyDocument';
import { useVideoState } from 'logic/videoStore';
import { Text } from 'components/Text/Text';
import { Help } from 'components/Help/Help';
import css from './Media.module.scss';

interface YoutubePlayerAPI {
  getCurrentTime: () => number;
  getDuration: () => number;
}

const youtubeOptions = {
  playerVars: {
    autoplay: 0,
    color: 'white',
    controls: 0,
    disablekb: 1,
    enablejsapi: 1,
    fs: 1,
    iv_load_policy: 3,
    loop: 0,
    modestbranding: 1,
    playsinline: 0,
    rel: 0,
    showinfo: 0,
  },
} as const;

type VideoStateWithFunctions = ReturnType<typeof useVideoState['getState']>;

export const YouTubeVideo: FC<{ questionID: number; video: MediaDocument }> = ({ questionID, video: youtubeVideo }) => {
  const id = youtubeVideo.target;

  const { video, setVideo, setCurrentTime } = useVideoState(
    useCallback((state: VideoStateWithFunctions) => ({ ...state, video: state.videoMap.get(questionID) }), [questionID])
  );

  const [api, setApi] = useState<YoutubePlayerAPI | null>(null);

  useEffect(() => {
    if (!api) return;

    const duration = api.getDuration();
    setVideo(questionID, duration);

    const currentTimeLoop = setInterval(() => {
      setCurrentTime(questionID, api.getCurrentTime());
    }, 1000);

    return () => clearInterval(currentTimeLoop);
  }, [api]);

  const handleReady = useCallback((event: { target: YoutubePlayerAPI }) => setApi(event.target), []);

  return (
    <div className={css.youtube}>
      <YouTube videoId={id} opts={youtubeOptions} onReady={handleReady} />
      <div className={css.requirements}>
        <Help>
          <FormattedMessage id="media.video.watchTillEndText" defaultMessage="Watch the entire video before answering" />
        </Help>

        <span style={{ flex: 1 }} />

        <Text variant="fineprint" element="label" className={css.requirement}>
          <FormattedMessage
            id="media.video.watchTimeText"
            defaultMessage="{left} s"
            values={{
              current: video?.currentTime || 0,
              duration: video?.duration || 0,
              left: Math.floor((video?.duration || 0) - (video?.currentTime || 0)),
            }}
            description="Always shown under a YouTube video. Usable variables include {current}, {duration} & {left}."
          />
        </Text>
      </div>
    </div>
  );
};
