import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { CheckSquare, DotsSix, Square } from 'phosphor-react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { OptionDocument } from 'common/StudyDocument';
import { Text } from 'components/Text/Text';
import css from './Rank.module.scss';

export interface RankProps {
  id: string | number;
  options: OptionDocument[];
  selected: number[];
  disabled?: boolean;
  animationKey: number;
  amountToRank: number;
  onChange: (optionID: number) => void;
  onSwap: (optionID: number, index: number) => void;
}

const RankOption: FC<OptionDocument & Pick<RankProps, 'disabled' | 'onChange'> & { flippedProps: any; selected: boolean; index: number }> =
  ({ questionOptionID, text, disabled, onChange, flippedProps, selected, index }) => {
    const isDisabled = !selected && disabled;

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (isDisabled) return;

      return onChange(questionOptionID);
    };

    const item = (liProps: any = {}, divProps: any = {}) => (
      <li className={cn(css.item, selected && 'selected')} {...flippedProps} {...liProps}>
        <div className={cn(css.button, 'checkbox', selected && 'checked', isDisabled && css.disabled)} onClick={handleClick} {...divProps}>
          {selected && <CheckSquare weight="fill" size={28} />}
          {!selected && <Square weight="bold" size={28} />}
          <Text variant="label">{text}</Text>
        </div>

        {selected && (
          <figure className={cn(css.number, selected && 'selected')}>
            <span>{index + 1}</span>
          </figure>
        )}
      </li>
    );

    if (selected) {
      return (
        <Draggable draggableId={`${questionOptionID}`} index={index} isDragDisabled={!selected}>
          {provided => item({ ref: provided.innerRef, ...provided.draggableProps }, provided.dragHandleProps)}
        </Draggable>
      );
    }

    return item();
  };

export const Rank: FC<RankProps> = ({ id, options = [], selected, disabled = false, amountToRank, animationKey, onChange, onSwap }) => {
  const sortedOptions = useMemo(
    () =>
      options.sort((a, b) => {
        let one = selected.indexOf(a.questionOptionID);
        if (one === -1) one = selected.length + options.indexOf(a);
        let two = selected.indexOf(b.questionOptionID);
        if (two === -1) two = selected.length + options.indexOf(b);

        return one - two;
      }),
    [options, selected]
  );

  const optionProps = useMemo(() => ({ disabled, onChange }), [disabled, onChange]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;

      onSwap(parseInt(result.draggableId), result.destination.index);
    },
    [selected, onSwap]
  );

  return (
    <Flipper className={css.rank} flipKey={animationKey}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`${id}-rank`}>
          {provided => (
            <ol className={cn(css.list, selected.length > 0 && 'selected')} ref={provided.innerRef} {...provided.droppableProps}>
              {sortedOptions
                .filter(item => selected.includes(item.questionOptionID))
                .map((option, index) => (
                  <Flipped key={option.questionOptionID} flipId={option.questionOptionID}>
                    {flippedProps => (
                      <RankOption
                        {...option}
                        {...optionProps}
                        flippedProps={flippedProps}
                        selected={selected.includes(option.questionOptionID)}
                        index={index}
                      />
                    )}
                  </Flipped>
                ))}
              {provided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>

      <ol className={css.list}>
        {sortedOptions
          .filter(item => !selected.includes(item.questionOptionID))
          .map((option, index) => (
            <Flipped key={option.questionOptionID} flipId={option.questionOptionID}>
              {flippedProps => (
                <RankOption
                  {...option}
                  {...optionProps}
                  flippedProps={flippedProps}
                  selected={selected.includes(option.questionOptionID)}
                  index={index}
                />
              )}
            </Flipped>
          ))}
      </ol>
    </Flipper>
  );
};
