import { FC } from 'react';
import { ChatCircle } from 'phosphor-react';
import { QuestionDocument } from 'common/StudyDocument';
import { Text } from 'components/Text/Text';
import { Textarea } from 'components/Textarea/Textarea';
import { Media } from '../Media/Media';
import { useQuestion } from './useQuestion';
import css from './Question.module.scss';

export const Question: FC<{ question: QuestionDocument }> = ({ question }) => {
  const { value, isCommentShown, isCommentRequired, handleCommentChange, renderContent, renderHelpText } = useQuestion(question);

  return (
    <article className={css.question}>
      <section className={css.content} aria-label={`question number ${question.questionNumber}`}>
        {/* <figure aria-hidden className={css.number}>
          <ChatCircle size={46} />
          <Text variant="label">{question.questionNumber}</Text>
        </figure> */}
        <Text className={css.title}>{question.name}</Text>

        <Media {...question} />

        <div className={css.answers}>{renderContent()}</div>

        {renderHelpText()}

        {isCommentShown && (
          <Textarea value={value?.text || ''} mandatory={isCommentRequired} min={4} max={300} onChange={handleCommentChange} />
        )}
      </section>
    </article>
  );
};
