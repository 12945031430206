import { FC } from 'react';
import { ArrowSquareOut } from 'phosphor-react';
import { MediaDocument } from 'common/StudyDocument';
import { Text } from 'components/Text/Text';
import css from './Media.module.scss';

export const Link: FC<{ link: MediaDocument }> = ({ link }) => {
  const { target: href, description: label } = link;

  return (
    <a href={href} target="_blank" className={css.link}>
      <span>
        <Text>{label}</Text>
        <Text variant="fineprint" aria-hidden>
          {href}
        </Text>
      </span>
      <ArrowSquareOut aria-hidden />
    </a>
  );
};
