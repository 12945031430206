import { ChangeEvent, FC, useCallback } from 'react';
import cn from 'classnames';
import { Star } from 'phosphor-react';
import { Text } from 'components/Text/Text';
import css from './Rating.module.scss';

const stars = [1, 2, 3, 4, 5];

export interface RatingProps {
  id: string;
  value: number | null;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const Rating: FC<RatingProps> = ({ id, value = null, disabled = false, onChange }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      if (event.target.checked) {
        onChange(parseInt(event.target.value));
      }
    },
    [disabled, onChange]
  );

  const renderInput = useCallback(
    (star: number) => {
      const inputID = `${id}-star-${star}`;
      return (
        <input
          key={inputID}
          type="radio"
          id={inputID}
          name={`${id}-star-value`}
          value={star}
          checked={value === star}
          disabled={disabled}
          onChange={handleChange}
        />
      );
    },
    [id, value, disabled, handleChange]
  );

  const renderLabel = useCallback(
    (star: number) => {
      const inputID = `${id}-star-${star}`;
      const labelID = `${id}-star-${star}-label`;

      return (
        <label key={labelID} htmlFor={inputID} aria-label={`${star} stars`}>
          <Star className="empty" size={42} />
          <Star className="filled" size={42} weight="fill" />
          <Text variant="label" element="span">
            {star}
          </Text>
        </label>
      );
    },
    [id]
  );

  return (
    <div role="radiogroup" className={cn(css.rating, disabled && css.disabled)}>
      {stars.map(renderInput)}
      {stars.map(renderLabel)}
    </div>
  );
};
