import create from 'zustand';
import { combine } from 'zustand/middleware';
import { AnswerDocument, AnswerResponseState, GenericAnswerDocument, QuestionTypeToAnswerType } from 'common/AnswerDocument';
import { QuestionType } from 'common/StudyDocument';

export interface AnswerState {
  answers: { [questionID: number]: AnswerDocument };
  isRejected: boolean;
}

const initialState: AnswerState = {
  answers: {},
  isRejected: false,
};

export const useAnswerState = create(
  combine(initialState, (set, get) => ({
    setAnswer: <T extends QuestionType>(
      { questionID }: { questionID: number; questionTypeID: T },
      answer: Omit<QuestionTypeToAnswerType[T], keyof GenericAnswerDocument>
    ) => {
      const { answers } = get();
      const existingData = answers[questionID] || {};

      // Fill in generic answer values
      const incomingData: GenericAnswerDocument & typeof answer = {
        questionID,
        responseStateID: AnswerResponseState.ANSWERED,
        ...answer,
      };

      // Keep existing values like "text"
      const updatedData = { ...existingData, ...incomingData };

      set({ answers: { ...answers, [questionID]: updatedData } });
    },

    setSkipped: (questionID: number) => {
      const { answers } = get();
      set({ answers: { ...answers, [questionID]: { questionID, responseStateID: AnswerResponseState.SKIPPED } as any } });
    },

    setRejected: () => set({ isRejected: true }),

    getAnswer: (questionID: number | null) => {
      if (!questionID) return null;
      const { answers } = get();
      return answers[questionID] || null;
    },

    setComment: ({ questionID }: { questionID: number }, comment: string) => {
      const { answers } = get();
      const existingData = answers[questionID] || {};

      // Generic answer values
      const fillerData: GenericAnswerDocument = {
        questionID,
        responseStateID: AnswerResponseState.ANSWERED,
      };

      // Use existing values if possbile and only update text, if no values exist, fill with generic
      const updatedData = { ...fillerData, ...existingData, text: comment };

      set({ answers: { ...answers, [questionID]: updatedData } });
    },
  }))
);
